<template>
  <div class="container">
    <Navbar navTitle="DEALER ADMIN"></Navbar>
    <main>
      <Nav></Nav>
      <div class="main">
        <div style="height: 20px"></div>
        <div class="mainContainer">
          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-bottom: 10px;
            "
          >
            <WalletBtn
              ref="WalletBtn"
              :account="ethAccount"
              @change="init(true)"
            ></WalletBtn>
          </div>
          <div>
            <van-cell-group>
              <van-cell :title="$t('user.ERC20')">
                <div slot="label">
                  <div>{{ userData.erc20 }}</div>
                  <div
                    style="color: orangered"
                    v-if="
                      userData.erc20.toLowerCase() != ethAccount.toLowerCase()
                    "
                  >
                    {{ $t("user.needRegisterWallet") }}
                  </div>
                </div>
              </van-cell>
              <van-cell title="已质押DPR数量" :value="haveStakingAmount">
              </van-cell>
              <van-cell title="已获得质押码数量" :value="haveCodeAmount">
              </van-cell>
              <van-cell :title="$t('user.DPRBalance')">
                <div slot="default">
                  {{ dprBalance }}
                  <span style="color: orangered" v-if="dprBalance < stakeAmount"
                    >({{ $t("user.noBalance") }})</span
                  >
                </div>
              </van-cell>
            </van-cell-group>
            <div class="amountBox">
              <div style="font-size: 20px; font-weight: bold">
                <van-stepper input-width="200" :min="2000" :step="2000" v-model="stakeAmount" />
              </div>
              <div style="margin-left: 30px">
                <img
                  :style="`width: 30px`"
                  :src="require('@/assets/dprCoin.png')"
                  alt=""
                />
              </div>
            </div>
            <div class="btnBox">
              <van-button
                :loading="stakeLoading"
                :disabled="true"
                @click="staking"
                style="width: 50%"
                round
                type="info"
                >{{ $t("user.stake") }}</van-button
              >
            </div>
          </div>
        </div>
        <div class="recordContainer">
          <h3 style="font-size: 14px; text-align: center">
            {{ $t("user.records") }}
          </h3>
          <table style="width: 100%; text-align: center; font-size: 12px">
            <thead>
              <tr>
                <th>{{ $t("user.time") }}</th>
                <th>hash</th>
                <th>status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in txList" :key="item.hash">
                <td>{{ $moment(item.time).format("YYYY-MM-DD HH:mm:ss") }}</td>
                <td>{{ formatHash(item.hash) }}</td>
                <td style="display: flex; justify-content: space-around">
                  <van-tag :type="getTxItemColor(item)">{{
                    getTxItemStatus(item)
                  }}</van-tag>
                </td>
                <td>
                  <a
                    target="_blank"
                    style="font-size: 12px"
                    :href="`https://etherscan.io/tx/${item.hash}`"
                    >{{ $t("user.detail") }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>
<script>
import WalletBtn from "@/components/WalletBtn/WalletBtn";
import ethService from "@/services/eth.js";
import Nav from './Nav.vue'
import Navbar from '@/components/Navbar/NavbarDealer'
export default {
  props: {
    comps: null,
  },
  components: {
    WalletBtn,
    Nav,
    Navbar
  },
  data() {
    return {
      ethAccount: "",
      stakeAmount: 2000,
      haveStakingAmount: "",
      haveCodeAmount: '',
      haveStaking: null,
      haveStakingDeeperChain: "",
      stakeLoading: false,
      dprBalance: "loading...",
      userData: {
        sn: "",
        erc20: "",
        deeperChain: "",
      },
      lang: {
        cn: {},
        en: {},
      },
      txList: [],
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    formatHash(hash) {
      return hash.slice(0, 6) + "..." + hash.slice(-6);
    },
    getTxItemColor(item) {
      if (item.status === "") {
        return "warning";
      }
      return item.status ? "success" : "danger";
    },
    getTxItemStatus(item) {
      if (item.status === "") {
        return "Pending";
      }
      return item.status ? "Success" : "Fail";
    },
    async getTxList() {
      if (window.localStorage.getItem("tx")) {
        this.txList = JSON.parse(window.localStorage.getItem("tx")).reverse();
        for (let i = 0; i < this.txList.length; i++) {
          let item = this.txList[i];
          if (item.status !== "") {
            continue;
          }
          let status = await ethService.deviceStake.getTransactionsStatus(
            item.hash
          );
          if (!status) {
            continue;
          }
          ethService.deviceStake.updateTxStatus(item.hash, status.status);
          console.log(status);
          this.txList[i].status = status.status;
        }
        await this.$sleep(1000)
        return this.getTxList()
      }
    },
    stakingDisable() {
      if (this.userData.erc20.toLowerCase() != this.ethAccount.toLowerCase()) {
        return true;
      }
      if (this.dprBalance < this.stakeAmount) {
        return true;
      }
      return false;
    },
    async init(fromWalletChange) {
      this.getUserData()
      console.log("init");
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      if (!fromWalletChange) {
        await ethService.init().catch((e) => {
          console.log(e);
        });
      }
      await this.ethInit();
      this.$toast.clear();
      if (!this.ethAccount) {
        return;
      }
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      await this.getDPRBalance();
      await this.getStaking()
      this.getTxList();
      this.$toast.clear();
    },
    async getDPRBalance() {
      let dprBalance = await ethService.getBalance(this.ethAccount);
      this.dprBalance = (dprBalance / 1e18).toFixed(4) / 1;
    },
    getUserData() {
      let tokenData = JSON.parse(window.localStorage.getItem("dealerTokenData"))
      this.userData = {
        erc20: tokenData.walletAddress,
        id: tokenData.dealer
      }
    },
    async ethInit() {
      const accounts = await ethService.getAccount();
      this.ethAccount = accounts[0];
      console.log(accounts);
    },
    async getStaking() {
      this.$fetch({
        headers: {
          Authorization: window.localStorage.getItem('dealerToken')
        },
        url: '/dealer/stakingData',
      }).then(res => {
        this.haveStakingAmount = (res.data.dprAmount/1e18).toFixed(5)/1;
        this.haveCodeAmount = parseInt(this.haveStakingAmount/2000)
        console.log(res)
      })
    },
    async staking() {
      if (this.txList.find((it) => it.status === "")) {
        let confirmRes = await this.$dialog
          .confirm({
            title: this.$t("user.confirmStakeWithPending"),
          })
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
        if (!confirmRes) {
          return;
        }
      }
      this.stakeLoading = true;
      try {
        if (this.haveStakingAmount / 1 > 0) {
          await ethService.deviceStake.addStake(
            this.userData.erc20,
            this.stakeAmount
          );
        } else {
          await ethService.deviceStake.dealerStake(
            this.userData.erc20,
            this.userData.id,
            this.stakeAmount
          );
        }
        this.$emit("next");
      } catch (e) {
        console.log(Object.keys(e));

        this.$Dialog({
          message:
            e.receipt && e.receipt.transactionHash
              ? "ERROR Transaction Hash: " + e.receipt.transactionHash
              : e.message,
        });
      }
      this.stakeLoading = false;
    },
  },
};
</script>
<style>
html,
body,
#app {
  height: 100%;
}
</style>
<style lang="less" scoped>
.container {
  min-height: 100%;
  background: #815611;
}
.recordContainer {
  max-width: 600px;
  margin: 30px auto 0;
  background-color: #fff;
  padding: 20px;
  border-radius: 30px;;
}
.mainContainer {
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 30px;;
  .amountTitle {
    text-align: center;
    padding-top: 30px;
  }
  .amountBox {
    display: flex;
    justify-content: center;
    align-items:  center;
    height: 80px;
    /deep/ .van-stepper__input {
      font-size: 20px
    }
  }
  .btnBox {
    text-align: center;
  }
  
}
</style>
